import Experience from "./Experience";
import * as THREE from "three";
export default class MobileUI {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;

    console.log("instantiating mobileUI");
    //make #mobileOverlay not display none, so it shows up
    document.getElementById("mobileOverlay").style.display = "block";
  }
}
