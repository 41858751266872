const feed = [
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/c4e86fd2228a605b1eabf868e593c90e/manifest/video.m3u8",
    creator: "CreatorName1",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/fa71d4f8cd4b383f2ce7ea6cf516761c/manifest/video.m3u8",
    creator: "CreatorName2",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/fa88fdf1cae679a52c080fc40b037ece/manifest/video.m3u8",
    creator: "CreatorName2",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/70d1bae247ed25300a3274a9bcf3a665/manifest/video.m3u8",
    creator: "CreatorName2",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/d9b2777d866882ab363e496bdee2f4de/manifest/video.m3u8",
    creator: "CreatorName2",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/0148a01ca09fb4a87978e8bd0cbfdab2/manifest/video.m3u8",
    creator: "CreatorName",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/adf605c144640837503a23b2e3c72365/manifest/video.m3u8",
    creator: "CreatorName2",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/30899829cbf4b90f5b77495048378a30/manifest/video.m3u8",
    creator: "CreatorName2",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/1e1894da7d3810e1f8321fbb3d690ca8/manifest/video.m3u8",
    creator: "possum",
    description: "A possum eating a banana",
  },
  {
    hlsManifestURL:
      "https://customer-l7l3c5vlcwzhdjya.cloudflarestream.com/c99bdc38c711cf7bc12be38131d6ac58/manifest/video.m3u8",
    creator: "hedgehog",
    description: "dragged by hedgehog",
  },

  // Add more video objects here as needed
];

export default feed;
